<template>
  <div class="page page-post-detail">
    <b-col class="py-3">
      <LoadingPanel v-if="loading"/>
      <ErrorPanel v-else-if="error" :message="errorMessage" @retry="loadPost()"/>
      <NotFoundPanel v-else-if="errorMessage" :message="errorMessage"/>
      <div v-else>
        <Breadcrumb :items="[{label: $t('News'), click: () => $router.push({name: 'Posts'})}, {label: $t('Post detail')}]"/>
        <div class="post-header">
          <h1>{{post.title}}</h1>
          <div class="post-info">
            <span class="post-game" v-if="post.game"><img :src="getWebFileUrl(post.game.iconUrl)" :alt="post.game.name"> {{post.game.name}} | </span>
            <span class="post-time">{{toDateTimeString(post.createdAt)}}</span>
          </div>
        </div>

        <div class="post-content" v-html="post.content">

        </div>
        <b-row class="mt-5 mb-4">
          <div class="w-100">
            <hr/>
          </div>
        </b-row>
        <div class="related-posts" v-if="post.relatedPosts.length > 0">
          <div class="header d-flex justify-content-between align-items-center">
            <h3>{{$t("Other posts")}}</h3>
            <a href="#">{{$t("View all")}}</a>
          </div>
          <div class="body">
            <PostCard v-for="(p, i) in post.relatedPosts" :key="i" :post="p"/>
          </div>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import postService from "@/services/postService";
import PostCard from "@/components/cards/PostCard";

export default {
  name: "Detail",
  components: {PostCard},
  data() {
    return {
      loading: true,
      error: false,
      post: null,
      errorMessage: null
    }
  },
  created() {
    this.loadPost();
  },
  methods: {
    async loadPost() {
      this.loading = true;
      this.error = false;
      this.errorMessage = null;
      const response = await postService.detail(this.$route.params["aliasUrl"]);
      this.loading = false;
      if (!response) {
        this.error = true;
        this.errorMessage = this.$t("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        this.errorMessage = response.message;
        return;
      }

      this.post = response.data;
      this.$setPageTitle(this.post.seo.title);
    }
  }
}
</script>

<style lang="scss">
.page-post-detail {
  .post-header {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 15px;
    margin-bottom: 20px;
    
    h1 {
      font-size: 18px;
      font-weight: 700;
    }
    
    .post-info {
      .post-game {
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
          margin-right: 5px;
        }
      }

      .post-time {
        color: #8e8e93;
      }
    }
  }
  
  .post-content {
    font-size: 16px;

    h3 {
      font-weight: 700;
      font-size: 18px !important;
      margin-bottom: 3px;
    }

    img, table {
      max-width: 100%;
      height: auto;
    }
  }
  
  .related-posts {
    .header {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #f2f2f2;
      
      h3 {
        font-weight: 700;
        font-size: 22px;
        margin: 0;
      }
      
      a {
        font-weight: 700;
        font-size: 15px;
        color: #ed3812;
      }
    }
  }
}
</style>