import httpHelper from "@/helpers/httpHelper";

const postService = {
    async detail(aliasUrl) {
        return await httpHelper.get("/WebApi/Post/Detail", {aliasUrl});
    },
    async list(data) {
        return await httpHelper.get("/WebApi/Post/List", data);
    }
};

export default postService;